import { FunctionComponent, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Collapse, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, UncontrolledDropdown } from 'reactstrap';
import User from 'react-feather/dist/icons/user';
import Settings from 'react-feather/dist/icons/settings';
import { LogoutButton } from '../screens/Auth/Logout/LogoutButton';
import { EnumCompanyFeedType } from '../graphql/generated';
import { IUserContext, UserContext } from 'src/Contexts/User/UserContext';
import LanguageSelector from './LanguageSelector/LanguageSelector';

interface Props {
    onToggleNavigation?: () => void;
}

const NavbarComponent: FunctionComponent<Props> = ({ onToggleNavigation }) => {
    const { user } = useContext<IUserContext>(UserContext);
    const { t } = useTranslation();

    function getUserName() {
        if (user.company.feedType === EnumCompanyFeedType.SHOPTET) {
            return user.email;
        }
        return user.name;
    }

    return (
        <Navbar color="white" light expand>
            <span className="sidebar-toggle d-flex mr-2" onClick={() => onToggleNavigation()}>
                <i className="hamburger align-self-center" />
            </span>

            <Collapse navbar>
                <Nav className="ml-auto" navbar>
                    <LanguageSelector type="icon" />

                    <UncontrolledDropdown nav inNavbar>
                        <span className="d-inline-block d-sm-none">
                            <DropdownToggle nav caret>
                                <Settings size={18} className="align-middle" />
                            </DropdownToggle>
                        </span>
                        <span className="d-none d-sm-inline-block">
                            <DropdownToggle nav caret>
                                <User size={18} className="align-middle mr-2" />
                                <span className="text-dark">{getUserName()}</span>
                            </DropdownToggle>
                        </span>
                        <DropdownMenu right>
                            <a href={t('navBar.help.url')} target="_blank" rel="noopener noreferrer">
                                <DropdownItem>
                                    <Trans>Help</Trans>
                                </DropdownItem>
                            </a>
                            <LogoutButton />
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
            </Collapse>
        </Navbar>
    );
};

export default NavbarComponent;
